import parse from 'html-react-parser';
import { Button, Link } from '@doit/pcnt-react-ui-library';
import {
  Box, Divider, Grid, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import i18n from '../common/i18n';
import useNavigation from '../hooks/useNavigation';
import { moneyFormatter } from '../utils/formatterUtil';
import { fillMessageWith } from '../utils/functionsUtil';
import { getEnv, REVOLVING_LINE_ACTIVATION_AGREEMENT_URL } from '../api/env';
import imgArrowBackBlack from '../assets/imgs/arrow-back-black.svg';

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: '700',
  },
  avaiableAmountLabel: {
    fontSize: '14px',
    fontWeight: '600',
  },
  amount: {
    color: '#E72064',
    fontSize: '32px',
    fontWeight: '700',
    fontFamily: 'Poppins',
    marginBottom: '8px',
  },
  detailBox: {
    marginTop: '24px',
    padding: '16px',
    backgroundColor: '#F5F5F5',
  },
  subtitle: {
    marginTop: '16px',
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '700',
  },
  bullet: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '16px',
  },
  text: {
    textAlign: 'left',
    fontSize: '14px',
  },
}));

const RevolvingLineSummary = ({
  loanRequest, signAgreement, signLoading,
}) => {
  const classes = useStyles();
  const { goToRevolvingLine } = useNavigation();

  return (
    <>
      <Grid container pt={10} pl={4} pr={4} pb={10} justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>

          <Typography className={classes.title}>
            <Button
              variant="tertiary"
              onClick={goToRevolvingLine}
              sx={{ padding: '0px', minWidth: '28px', justifyContent: 'left' }}
            >
              <img src={imgArrowBackBlack} alt="back" width="20px" />
            </Button>
            {i18n.RevolvingLineSummary.title}
          </Typography>

          <Box className={classes.detailBox}>
            <Typography className={classes.avaiableAmountLabel}>{i18n.RevolvingLineSummary.availableAmount}</Typography>
            <Typography className={classes.amount}>{moneyFormatter(loanRequest.requestedAmount)}</Typography>
            <Divider />
            <Typography className={classes.subtitle}>{i18n.RevolvingLineSummary.subtitle}</Typography>
            <ul style={{ paddingLeft: '15px', margin: '5px' }}>
              <li>
                <Typography className={classes.text}>
                  {parse(fillMessageWith(i18n.RevolvingLineSummary.items[0]))}
                </Typography>
              </li>
              <li>
                <Typography className={classes.text}>
                  {parse(fillMessageWith(i18n.RevolvingLineSummary.items[1], loanRequest.debitCardNumber.slice(-4)))}
                </Typography>
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
      <Box className="summary-terms-and-conditions-cta-container">
        <Box pl={2} pr={2} pt={3} pb={3} display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography
              sx={{ padding: '0px', fontSize: '12px' }}
            >
              {i18n.SummaryTermsAndConditionsMode.agreement}
            </Typography>
            <Link
              size="small"
              underline="always"
              href={getEnv(REVOLVING_LINE_ACTIVATION_AGREEMENT_URL)}
              target="_blank"
              sx={{ padding: '0px', fontSize: '12px', paddingLeft: '4px' }}
            >
              {i18n.SummaryTermsAndConditionsMode.showAgreement}
            </Link>
          </Box>
          <Box mt={3} width="100%">
            <Button
              variant="primary"
              className="btn-primary"
              fullWidth
              onClick={signAgreement}
              loading={signLoading}
            >
              {i18n.OnboardingSummary.signCTA}
            </Button>
            <Button
              variant="secondary"
              className="btn-primary"
              type="button"
              fullWidth
              style={{ marginTop: '8px' }}
              onClick={goToRevolvingLine}
            >
              {i18n.OnboardingSummary.backCTA }
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

RevolvingLineSummary.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  signAgreement: PropTypes.func.isRequired,
  signLoading: PropTypes.bool.isRequired,
};

export default RevolvingLineSummary;
